/*
 * @Descripttion:
 * @version:
 * @Author: zhangfei
 * @Date: 2020-10-21 09:19:43
 * @LastEditors: zhangfei
 * @LastEditTime: 2020-12-06 21:26:23
 */

// import { get, post } from '../service/request'
import { 
    postToPortalServer, 
    getToPortalServer 
} from './common';


// export const uploadFile = p => post('/admin/portal/upload_url', p)
// export const delFile = p => post('/admin/portal/del', p)


/**
    交易banner列表
 */
// export const bannerList = p => get('/transaction/banner/list', p)
export async function bannerList(obj) {
    return await getToPortalServer('/transaction/banner/list', obj);
}
/**
    交易删除banner
 */
// export const deleteBanner = p => getToPortalServer('/transaction/banner/delete', p)
export async function deleteBanner(obj) {
    return await getToPortalServer('/transaction/banner/delete', obj);
}
/**
    交易新增banner
 */
// export const addBanner = p => postToPortalServer('/transaction/banner/add', p)
export async function addBanner(obj) {
    return await postToPortalServer('/transaction/banner/add', obj);
}
/**
    交易编辑banner
 */
// export const editBanner = p => postToPortalServer('/transaction/banner/edit', p)
export async function editBanner(obj) {
    return await postToPortalServer('/transaction/banner/edit', obj);
}
/**
   设计 banner列表
 */
// export const bannerList1 = p => getToPortalServer('/design/banner/list', p)
export async function bannerList1(obj) {
    return await getToPortalServer('/design/banner/list', obj);
}
/**
    设计删除banner
 */
// export const deleteBanner1 = p => getToPortalServer('/design/banner/delete', p)
export async function deleteBanner1(obj) {
    return await getToPortalServer('/design/banner/delete', obj);
}
/**
    设计新增banner
 */
// export const addBanner1 = p => postToPortalServer('/design/banner/add', p)
export async function addBanner1(obj) {
    return await postToPortalServer('/design/banner/add', obj);
}
/**
    设计编辑banner
 */
// export const editBanner1 = p => postToPortalServer('/design/banner/edit', p)
export async function editBanner1(obj) {
    return await postToPortalServer('/design/banner/edit', obj);
}

/**
   优秀作品风格列表
 */
// export const getStyleList = p => getToPortalServer('/design/style/list', p)
export async function getStyleList(obj) {
    return await getToPortalServer('/design/style/list', obj);
}
/**
   优秀作品风格新增
 */
// export const addStyle = p => postToPortalServer('/design/style/add', p)
export async function addStyle(obj) {
    return await postToPortalServer('/design/style/add', obj);
}
/**
   优秀作品风格详情
 */
// export const getStyleDetail = p => getToPortalServer('/design/style/detail', p)
export async function getStyleDetail(obj) {
    return await getToPortalServer('/design/style/detail', obj);
}
/**
   优秀作品风格编辑
 */
// export const editStyle = p => postToPortalServer('/design/style/edit', p)
export async function editStyle(obj) {
    return await postToPortalServer('/design/style/edit', obj);
}
/**
   优秀作品删除
 */
// export const deleteCommodity = p => getToPortalServer('/design/style/delete', p)
export async function deleteCommodity(obj) {
    return await getToPortalServer('/design/style/delete', obj);
}

/**
   木材分类列表
 */
// export const wordStylelist = p => getToPortalServer('/wood/style/list', p)
export async function wordStylelist(obj) {
    return await getToPortalServer('/wood/style/list', obj);
}
/**
   木材分类详情
 */
// export const wordStyleDetail = p => getToPortalServer('/wood/style/detail', p)
export async function wordStyleDetail(obj) {
    return await getToPortalServer('/wood/style/detail', obj);
}
/**
   木材分类删除
 */
// export const deleteWoodStyle = p => getToPortalServer('/wood/style/delete', p)
export async function deleteWoodStyle(obj) {
    return await getToPortalServer('/wood/style/delete', obj);
}

/**
   木材分类编辑
 */
// export const editWoodStyle = p => postToPortalServer('/wood/style/edit', p)
export async function editWoodStyle(obj) {
    return await postToPortalServer('/wood/style/edit', obj);
}
/**
   木材分类新增
 */
// export const addWoodStyle = p => postToPortalServer('/wood/style/add', p)
export async function addWoodStyle(obj) {
    return await postToPortalServer('/wood/style/add', obj);
}



/**
   商品删除
 */
// export const deleteGoods = p => getToPortalServer('/commodity/delete', p)
export async function deleteGoods(obj) {
    return await getToPortalServer('/commodity/delete', obj);
}

/**
   招商加盟列表
 */
// export const joinStylelist = p => getToPortalServer('/merchant/join/list', p)
export async function joinStylelist(obj) {
    return await getToPortalServer('/merchant/join/list', obj);
}
/**
   招商加盟详情
 */
// export const joinStyleDetail = p => getToPortalServer('/merchant/join/detail', p)
export async function joinStyleDetail(obj) {
    return await getToPortalServer('/merchant/join/detail', obj);
}
/**
   招商加盟删除
 */
// export const deleteJoinStyle = p => getToPortalServer('/merchant/join/delete', p)
export async function deleteJoinStyle(obj) {
    return await getToPortalServer('/merchant/join/delete', obj);
}

/**
   招商加盟编辑
 */
// export const editJoinStyle = p => postToPortalServer('/merchant/join/edit', p)
export async function editJoinStyle(obj) {
    return await postToPortalServer('/merchant/join/edit', obj);
}
/**
   招商加盟新增
 */
// export const addJoinStyle = p => postToPortalServer('/merchant/join/add', p)
export async function addJoinStyle(obj) {
    return await postToPortalServer('/merchant/join/add', obj);
}



/**
   展会报名列表
 */
// export const exhibitionStylelist = p => getToPortalServer('/exhibition/list', p)
export async function exhibitionStylelist(obj) {
    return await getToPortalServer('/exhibition/list', obj);
}
/**
   展会报名详情
 */
// export const exhibitionStyleDetail = p => getToPortalServer('/exhibition/detail', p)
export async function exhibitionStyleDetail(obj) {
    return await getToPortalServer('/exhibition/detail', obj);
}
/**
   展会报名删除
 */
// export const deleteExhibitionStyle = p => getToPortalServer('/exhibition/delete', p)
export async function deleteExhibitionStyle(obj) {
    return await getToPortalServer('/exhibition/delete', obj);
}

/**
   展会报名编辑
 */
// export const editExhibitionStyle = p => postToPortalServer('/exhibition/edit', p)
export async function editExhibitionStyle(obj) {
    return await postToPortalServer('/exhibition/edit', obj);
}
/**
   展会报名新增
 */
// export const addExhibitionStyle = p => postToPortalServer('/exhibition/add', p)
export async function addExhibitionStyle(obj) {
    return await postToPortalServer('/exhibition/add', obj);
}



/**
   热门推荐列表
 */
export const popularStylelist = p => getToPortalServer('/popular/list', p)
// export async function popularStylelist(obj) {
//     return await getToPortalServer('/popular/list', obj);
// }
/**
   热门推荐详情
 */
// export const popularStyleDetail = p => getToPortalServer('/popular/detail', p)
export async function popularStyleDetail(obj) {
    return await getToPortalServer('/popular/detail', obj);
}
/**
   热门推荐删除
 */
// export const deletePopularStyle = p => getToPortalServer('/popular/delete', p)
export async function deletePopularStyle(obj) {
    return await getToPortalServer('/popular/delete', obj);
}

/**
   热门推荐编辑
 */
// export const editPopularStyle = p => postToPortalServer('/popular/edit', p)
export async function editPopularStyle(obj) {
    return await postToPortalServer('/popular/edit', obj);
}
/**
   热门推荐新增
 */
// export const addPopularStyle = p => postToPortalServer('/popular/add', p)
export async function addPopularStyle(obj) {
    return await postToPortalServer('/popular/add', obj);
}


/**
   新品上市列表
 */
// export const newStylelist = p => getToPortalServer('/new/goods/list', p)
export async function newStylelist(obj) {
    return await getToPortalServer('/new/goods/list', obj);
}
/**
   新品上市详情
 */
// export const newStyleDetail = p => getToPortalServer('/new/goods/detail', p)
export async function newStyleDetail(obj) {
    return await getToPortalServer('/new/goods/detail', obj);
}
/**
   新品上市删除
 */
// export const deleteNewStyle = p => getToPortalServer('/new/goods/delete', p)
export async function deleteNewStyle(obj) {
    return await getToPortalServer('/new/goods/delete', obj);
}

/**
   新品上市编辑
 */
// export const editNewStyle = p => postToPortalServer('/new/goods/edit', p)
export async function editNewStyle(obj) {
    return await postToPortalServer('/new/goods/edit', obj);
}
/**
   新品上市新增
 */
// export const addNewStyle = p => postToPortalServer('/new/goods/add', p)
export async function addNewStyle(obj) {
    return await postToPortalServer('/new/goods/add', obj);
}



/*
 获取商品详情
*/
// export async function marketDetailProduct(obj) {
//     return await postToItemServer('/design-market/detail-product', obj);
// }

/*
 获取商品详情
*/
// export async function getCompBaseinfo(obj) {
//     return await postToUserServer('/my-company/get-comp-baseinfo', obj);
// }
