<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhangfei
 * @Date: 2020-11-25 16:32:49
 * @LastEditors: zhangfei
 * @LastEditTime: 2020-12-08 15:07:10
-->
<template>
  <div class="addBanner">
    <el-form
      :model="bannerForm"
      :rules="rules"
      style="margin-bottom: 70px"
      ref="ruleForm1"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="banner名称" prop="name">
          <el-input v-model="bannerForm.name"></el-input>
      </el-form-item>
      <el-form-item label="banner图片" prop="url">
        <!-- <el-upload
          class="comp"
          action="xx"
          :show-file-list="false"
          :accept="uploadAccept"
          :http-request="uploadLegal"
          :before-upload="beforeUpload"
        > -->
<el-upload
        class="avatar-uploader"
        ref="upload" 
        action="xxx" 
        :show-file-list="false"
        :limit="1"
        :http-request="uploadLegal"
        :before-upload="beforeUpload"
        >
       

          <div v-if="bannerForm.url" style="position: relative">
            <img
              :src="bannerForm.url"
              style="width: 100px; height: 100px"
              class="avatar"
            />
            <i class="el-icon-close deleteImg" @click.stop="deleteImg" />
          </div>
          <div v-else class="flex flex-center">
            <div
              class="flex flex-center flex-v uploadView"
              style="width: 100px; height: 100px"
            >
              <img
                src="../../../assets/images/add.png"
                style="width: 20px; height: 20px"
              />
              <span>立即上传</span>
            </div>
            <div class="sizeSuggest">
              <span>图片尺寸1920px*800px;</span>
            </div>
          </div>
        </el-upload>
        <!-- <el-upload
          class="comp-cert-uploader"
          action="xx"
          :show-file-list="false"
          :accept="uploadAccept"
          :http-request="uploadImg"
          :before-upload="beforeUpload"
        >
          <div v-if="bannerForm.url" style="position: relative">
            <img :src="bannerForm.url" style="width:100px;height:100px;" class="avatar" />
            <i class="el-icon-close deleteImg" @click.stop="deleteImg" />
          </div>

          <div v-else class="flex flex-center">
            <div
              class="flex flex-center flex-v uploadView"
              style="width: 100px; height: 100px"
            >
              <img
                src="../../../assets/images/add.png"
                style="width: 20px; height: 20px"
              />
              <span>立即上传</span>
            </div>
            <div class="sizeSuggest">
              <span>图片尺寸1920px*800px;</span>
            </div>
          </div>
        </el-upload> -->
      </el-form-item>
      <el-form-item label="排序" prop="order">
        <el-input v-model="bannerForm.order"></el-input>
      </el-form-item>
    </el-form>
    <div class="flex flex-center footer">
      <el-button @click="hide">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </div>
</template>
<script>
import { uploadFile, delFile } from "@/service/common";
import {addBanner,editBanner,addBanner1,editBanner1} from "@/service/operation"
import { IMG_URL_PRE } from "@/config.js"
import axios from 'axios';

// import {FILE_URL} from "@/config.js"
export default {
  data() {
    return {
      bannerForm: {name:'',order:'',url:''},
      rules: {
        order: [{ required: true, message: "排序", trigger: "blur" }],
        name: [{ required: true, message: "banner名称", trigger: "blur" }],
        url: [{ required: true, message: "商品图片", trigger: "blur" }],
      },
      uploadAccept: "image/jpeg,image/gif,image/png",
    };
  },
  props: {
    isAdd: Boolean,
    editObj:Object,
    isStyle:Boolean
  },
  components: {},
  methods: {
    
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
       const isSize = new Promise(function(resolve,reject) {
          let width = 1920
          let height = 800
          let _URL = window.URL || window.webkitURL
          let img = new Image()
          img.onload = function() {
              let valid = img.width >= width && img.height >= height
              valid ? resolve() : reject();
          }
          img.src = _URL.createObjectURL(file)
      }).then(() => {
          return file;
      },()=>{
          this.$message.error('上传图片像素要大于1920*800!');
          return Promise.reject();
      })
      console.log(isSize)
      return isLt2M&&isSize;
    },
    uploadLegal(item) {
      uploadFile(item.file, "admin/portal/banner", {
        onUploadProgress: (event) => {
          item.file.percent = (event.loaded / event.total) * 100;
          item.onProgress(item.file);
        },
      })
        .then(url=> {
          this.bannerForm.url = IMG_URL_PRE+ url;
          this.bannerForm = Object.assign({}, this.bannerForm);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    add() {
       this.$refs['ruleForm1'].validate((valid) => {
            if (valid) {
              if(!this.isAdd){
                this.editSubmit();
              }else{
                this.submit();
              }
                
            }
          });
    },
    submit(){
      if(this.isStyle){
         addBanner1(this.bannerForm).then(res=>{
                 this.$emit("refreshItems");
                  this.$emit("hide");
                  this.$message.success("提交成功");
            }).catch(err=>{
              this.$message.error(err.message);
            })
      }else{
         addBanner(this.bannerForm).then(res=>{
                 this.$emit("refreshItems");
                  this.$emit("hide");
                  this.$message.success("提交成功");
            }).catch(err=>{
              this.$message.error(err.message);
            })
      }
       
    },
    editSubmit(){
          
          if(this.isStyle){
            editBanner1(this.bannerForm).then(res=>{
                 this.$emit("refreshItems");
                    this.$emit("hide");
                    this.$message.success("提交成功");
            }).catch(err=>{
              this.$message.error(err.message);
            })
           
            
      }else{
         editBanner(this.bannerForm).then(res=>{
                 this.$emit("refreshItems");
                    this.$emit("hide");
                    this.$message.success("提交成功");
            }).catch(err=>{
              this.$message.error(err.message);
            })
      }
    },
     hide() {
            this.$emit("hide");
        },
    deleteImg() {
      // delFile(this.bannerForm.url)
      //   .then((res) => {
          this.bannerForm.url=""
        // })
        // .catch((err) => {
        //   this.$message.error(err.message);
        // });
    },
  },
  watch:{
      isAdd:{
        handler (newVal) {
         if(!newVal) {
           this.bannerForm  = {}
             this.bannerForm = Object.assign({},this.editObj)
              console.log(this.bannerForm )
          }
      },
      deep: true,
      immediate: true
     }
  }
};
</script>
<style scoped>
@import "../style.css";
</style>
<style>
.addBanner .el-input__inner {
  width: 260px;
}
.addBanner .el-input {
  width: 260px;
}
.addBanner .el-form-item {
  margin-bottom: 10px;
}
</style>